//temporary data
export const tripRows = [
      {
        "id": "1",
        "booked_by": "John Doe",
        "booking_no": "123456",
        "route_name": "CBD (Ambassador) - Karen",
        "trip_date": "2023-06-15",
        "pick_up": "CBD",
        "drop_off": "Karen",
        "seat_no": "A12",
        "fare": 100,
        "vehicle_reg": "KBC 123X",
        "vehicle_type": "Bus"
      },
      {
        "id": "2",
        "booked_by": "Jane Smith",
        "booking_no": "789012",
        "route_name": "CBD (Ambassador) - Karen",
        "trip_date": "2023-06-16",
        "pick_up": "CBD",
        "drop_off": "Prestige Mall",
        "seat_no": "B10",
        "fare": 80,
        "vehicle_reg": "KBC 456Y",
        "vehicle_type": "Bus"
      },
      {
        "id": "3",
        "booked_by": "Mike Johnson",
        "booking_no": "345678",
        "route_name": "CBD (Ambassador) - Karen",
        "trip_date": "2023-06-17",
        "pick_up": "CBD",
        "drop_off": "Karen",
        "seat_no": "C5",
        "fare": 100,
        "vehicle_reg": "KBC 789Z",
        "vehicle_type": "Bus"
      }
];


export const driverRows = [
    {
      "id": "1",
      "name": "John Doe",
      "id_number": "1234567890",
      "phone_number": "+2544567890",
      "status": "Active"
    },
    {
      "id": "2",
      "name": "Jane Smith",
      "id_number": "0987654321",
      "phone_number": "+2546543210",
      "status": "Inactive"
    },
    {
      "id": "3",
      "name": "Mike Johnson",
      "id_number": "5678901234",
      "phone_number": "+2547890123",
      "status": "Active"
    }
  ]


  export const vehiclesRow =[
    {
      "id": "1",
      "vehicle_number": "KJL 100B",
      "vehicle_type": "14 Seater",
      "status": "Active",
      "driver_id": "1",
      "driver_name": "John Doe",
      "driver_phone": "+2544567890"
    },
    {
      "id": "2",
      "vehicle_number": "KOP 200C",
      "vehicle_type": "Mini Bus",
      "status": "Inactive",
      "driver_id": "2",
      "driver_name": "Jane Smith",
      "driver_phone": "+2546543210"
    },
    {
      "id": "3",
      "vehicle_number": "KMN 300D",
      "vehicle_type": "Bus",
      "status": "Active",
      "driver_id": "3",
      "driver_name": "Mike Johnson",
      "driver_phone": "+2547890123"
    }
  ]
