import React from 'react'
import './tenants.scss'
import { DataTable } from '../../components'
import {tenantsColumns} from '../../utils'
import { DriversTable } from './table'

export const ListTenants = () => {
    return (
        <div className="main">
        <div className="container">
            <div className="content">
                <DriversTable />
            </div>
        </div>
    </div>
    )
}
