import React, { useState, useEffect, useContext } from 'react';
import './leasetable.scss'
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import { tripRows } from '../../data/data';
import { tripsColumns } from '../../data/datatablesource';
import { db } from '../../firebaseConfig';

import { AuthContext } from '../../context/AuthContext';


export const LeaseTable = ({ collectionName, columnSource, title, route }) => {
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { state: { user } } = useContext(AuthContext)


    if (loading) {
        return (
            <div style={{ height: 400, width: '100%', position: 'relative' }}>
                <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -20, marginLeft: -20 }} />
            </div>
        );
    }



    // Define a function to set the "open" state to true, used for opening a modal or dialog box
    const handleOpen = () => {
        setOpen(true);
    };

    // Define a function to set the "open" state to false, used for closing a modal or dialog box
    const handleClose = () => {
        setOpen(false);
    };

    // Define a function to handle deleting an item from the database
    // Takes an "id" parameter and displays a confirmation message to the user
    // If the user confirms, it calls the deleteDoc() function with the appropriate parameters
   

    // Define a function to handle editing an item in the database
    // Currently empty, needs to be filled in with code to handle editing
    const handleEdit = (id) => {
        // TODO: Implement function to handle editing
    };



    const columnsWithActions = [
        ...tripsColumns,
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            filterable: false,
            width: 250,
            align: 'center',
            disableClickEventBubbling: true,
            renderCell: (params) => (
                <>
                    <button style={{ margin: '2px', backgroundColor: '#4caf50', color: '#fff' }} >View Invoice</button>
                    
                </>
            )
        },
    ];


    const styles = {
        root: {
            '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
                fontSize: '10px',
            },
        },
    };


    return (
        <div className='table-container' style={{ position: 'relative' }}>
            <h3>{title}</h3>
            <div style={{marginVertical: 20}}>
            <p style={{fontSize: 12}}><a>Today</a> |  Yesterday  |  Current Week  |  Previous Week  |  Current Month  |  Previous Month  |  Current Year  |  Previous Year</p>
            </div>
            
            <div style={{ height: '70vh', width: '100%', }}>
                <DataGrid
                    rows={tripRows}
                    columns={columnsWithActions}
                    pageSize={9}
                    rowsPerPageOptions={[9]}
                    classes={styles}
                />
            </div>
        </div>
    )
}
