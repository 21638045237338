export const tripsColumns = [
    {
      field: "booked_by",
      headerName: "Booked By",
      width: 200,
      valueGetter: (params) => {
        return params.row.booked_by;
      }
    },
    {
      field: "booking_no",
      headerName: "Booking Number",
      width: 200,
      valueGetter: (params) => {
        return params.row.booking_no;
      }
    },
    {
      field: "route_name",
      headerName: "Route Name",
      width: 250,
      valueGetter: (params) => {
        return params.row.route_name;
      }
    },
    {
      field: "trip_date",
      headerName: "Trip Date",
      width: 150,
      valueGetter: (params) => {
        return params.row.trip_date;
      }
    },
    {
      field: "pick_up",
      headerName: "Pick-Up",
      width: 150,
      valueGetter: (params) => {
        return params.row.pick_up;
      }
    },
    {
      field: "drop_off",
      headerName: "Drop-Off",
      width: 150,
      valueGetter: (params) => {
        return params.row.drop_off;
      }
    },
    {
      field: "seat_no",
      headerName: "Seat Number",
      width: 150,
      valueGetter: (params) => {
        return params.row.seat_no;
      }
    },
    {
      field: "fare",
      headerName: "Fare",
      width: 150,
      valueGetter: (params) => {
        return params.row.fare;
      }
    },
    {
      field: "vehicle_reg",
      headerName: "Vehicle Registration",
      width: 200,
      valueGetter: (params) => {
        return params.row.vehicle_reg;
      }
    },
    {
      field: "vehicle_type",
      headerName: "Vehicle Type",
      width: 150,
      valueGetter: (params) => {
        return params.row.vehicle_type;
      }
    }
  ];

  
  export const driverColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 150,
      valueGetter: (params) => {
        return params.row.id;
      }
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      valueGetter: (params) => {
        return params.row.name;
      }
    },
    {
      field: "id_number",
      headerName: "ID Number",
      width: 200,
      valueGetter: (params) => {
        return params.row.id_number;
      }
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      width: 200,
      valueGetter: (params) => {
        return params.row.phone_number;
      }
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      valueGetter: (params) => {
        return params.row.status;
      }
    }
  ];
  

  export const vehicleColumns = [
    {
      field: "vehicle_number",
      headerName: "Vehicle Number",
      width: 200,
      valueGetter: (params) => {
        return params.row.vehicle_number;
      }
    },
    {
      field: "vehicle_type",
      headerName: "Vehicle Type",
      width: 150,
      valueGetter: (params) => {
        return params.row.vehicle_type;
      }
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      valueGetter: (params) => {
        return params.row.status;
      }
    },
    {
      field: "driver_name",
      headerName: "Driver Name",
      width: 200,
      valueGetter: (params) => {
        return params.row.driver_name;
      }
    },
    {
      field: "driver_phone",
      headerName: "Driver Phone",
      width: 200,
      valueGetter: (params) => {
        return params.row.driver_phone;
      }
    }
  ];
  