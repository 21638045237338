import React from 'react'
import './properties.scss'
import { DataTable } from '../../components'
import { vehicleColumns } from '../../data/datatablesource'
import { VehicleTable } from './vehicleTable'


export const ListProperties = () => {
    return (
        <div className="main">
            <div className="container">
                <div className="content">
                   <VehicleTable/>
                </div>
            </div>
        </div>
    )
}
